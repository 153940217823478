import type React from 'react'

import BulletList from '@cms/components/BulletList/BulletList'
import StageBrandImage from '@components/Stage/StageBrandImage'
import { CTAComponent } from '@knauf-group/ct-designs/components/core/CTAComponent'
import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import { ScreenMD } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { StageBrandMediaReferences, StageProps } from './Stage.types'

/**
 * StageBrand uses three separate images:
 * 1. Blue background image for small screens (bgImageSmall).
 * 2. Blue background image for the left portion large screens (bgImageLarge).
 * 3. Dynamic background image (image) for the image on the right side of the screen.
 */
const StageBrand: React.FC<StageProps & StageBrandMediaReferences> = ({
  firstBulletItem,
  secondBulletItem,
  thirdBulletItem,
  headline,
  subHeadline,
  text,
  dataCy,
  primaryRef,
  secondaryRef,
  nextLinkComponent,
  nextImageComponent,
  imageLoader,
  image,
  bgImageSmall,
  bgImageLarge,
}) => {
  const theme = useTheme()
  const isScreenMd = ScreenMD(theme)
  const contrastColor = theme.palette.primary.contrastText
  const bulletItems = [firstBulletItem, secondBulletItem, thirdBulletItem].filter(
    (item) => item,
  )
  const hasBulletItems = bulletItems?.length > 0

  const styles: Styles = {
    backgroundGridContainer: {
      display: 'flex',
      position: 'relative',
      color: theme.palette.white,
      height: '100%',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: {
        xs: 1,
        md: 2,
      },
    },
    highlightText: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      p: '0',
    },
    buttonContainer: {
      mt: {
        xs: theme.spacing(2),
        sm: theme.spacing(4),
        md: theme.spacing(3),
      },
    },
  }

  return (
    <Box position="relative" overflow="hidden" height="100%">
      <StageBrandImage
        nextImageComponent={nextImageComponent}
        imageLoader={imageLoader}
        image={image}
        bgImageSmall={bgImageSmall}
        bgImageLarge={bgImageLarge}
      />
      <Box sx={styles.backgroundGridContainer}>
        <Container sx={{ ...WEB_CONTAINER_VERTICAL_SPACE, alignContent: 'center' }}>
          <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={{ xs: '0px', lg: 2 }}
            sx={{ minHeight: { md: '454px' } }}
          >
            <Grid item xs={12} md={7} lg={8} sx={styles.gridItem}>
              <Typography variant="h1" color="white" data-cy={`${dataCy}-headline`}>
                {headline}
              </Typography>
              {subHeadline && (
                <Typography
                  variant={isScreenMd ? 'h5' : 'h4'}
                  color={contrastColor}
                  data-cy={`${dataCy}-sub-headline`}
                >
                  {subHeadline}
                </Typography>
              )}
              {text && (
                <Box display="flex" flexDirection="column">
                  <Typography
                    color={contrastColor}
                    variant="body2"
                    sx={styles.highlightText}
                    data-cy={`${dataCy}-text`}
                  >
                    {text}
                  </Typography>
                </Box>
              )}
              {hasBulletItems && (
                <Box sx={styles.highlightText}>
                  <BulletList
                    qaIdPrefix={dataCy}
                    listItems={bulletItems}
                    bulletColorAndTextOverride={contrastColor}
                  />
                </Box>
              )}
              {(primaryRef?.href || secondaryRef?.href) && (
                <Stack sx={styles.buttonContainer} alignItems={{ xs: 'stretch', sm: 'start' }}>
                  <CTAComponent
                    primaryRef={primaryRef}
                    secondaryRef={secondaryRef}
                    dataCyPrefix={dataCy}
                    nextLinkComponent={nextLinkComponent}
                    buttonAlignment="start"
                  />
                </Stack>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default StageBrand
